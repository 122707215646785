.Container {
  max-height: 300px;
  overflow: auto;
}

.CommentCard {
  padding: 5px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 15px;
}
@primary-color: #3f51b5;@layout-header-height: 70px;@layout-header-background: @primary-color;