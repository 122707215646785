.Header {
  box-shadow: 0 2px 3px 0 rgba(63, 81, 181, 0.7);
}

.HeaderMobile {
  padding-left: 15px !important;
  padding-right: 5px !important;
}

.Container {
  height: 100%;
}

.Title {
  margin-bottom: 0 !important;
  color: #fff !important;
}
@primary-color: #3f51b5;@layout-header-height: 70px;@layout-header-background: @primary-color;