.CommentListContainer {
  max-height: 300px;
  overflow: auto;
}

.CardSectionContainer {
  margin: 5px;
  border-radius: 15px;
}

.CommentCard {
  padding: 5px;
  width: 100%;
  margin-bottom: 10px;
}

.CompletedCard {
  //border: 3px solid #53c41a;
  //box-shadow: 0 0 4px 6px #53c41a;
}

.GreenColor {
  color: limegreen;
}
@primary-color: #3f51b5;@layout-header-height: 70px;@layout-header-background: @primary-color;