.Container {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-top: 20px;
}

.Menu {
  border: none;
}

.Menu.FullSize {
  flex-grow: 1;
}
@primary-color: #3f51b5;@layout-header-height: 70px;@layout-header-background: @primary-color;