.Container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
}

.ContentContainer {
  flex-grow: 1;
  padding: 20px;
  overflow: auto;
}
@primary-color: #3f51b5;@layout-header-height: 70px;@layout-header-background: @primary-color;